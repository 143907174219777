import * as React from 'react'
import JoinCommunity from './join-community'

interface Props {
  location: {
    pathname: string
  }
}

const ThankYou = (props: Props): JSX.Element => {
  return <JoinCommunity location={props.location} showThankYou />
}

export default ThankYou
